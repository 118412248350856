import React from 'react';
import * as Styled from './StatusBar.styled';
import { optionLabelOverrides } from 'containers/Application/Maintenance/utils';
import { TranslationFunction } from 'decorators/Translations/TranslationFunction';
import { DEFAULT_PRIORITY, OrderStatus, OrderStatusColors } from 'constants/maintenance';
import CalendarStatusTag from 'components/Calendar/CalendarStatusTag/CalendarStatusTag';
import { translatePriority } from 'utils/Data/serviceOrders';
import SkeletonText from 'components/Skeletons/SkeletonText';

type Props = {
  t: TranslationFunction;
  order?: {
    externalType: string;
    status: string;
    priority: number;
  };
  isOverdue?: boolean;
  loadingOrder: boolean;
};

type StatusFieldProps = {
  title: string;
  value: React.ReactNode;
};

type SourceMap = {
  [key: string]: string;
};

const StatusField = ({ title, value }: StatusFieldProps) => {
  if (!value) {
    return <></>;
  }
  return (
    <Styled.Status>
      <Styled.StatusName>{title}</Styled.StatusName>
      {value}
    </Styled.Status>
  );
};

export const getStatusTag = (status: string, text: string) => {
  if (!status || !text) {
    return <></>;
  }
  return <CalendarStatusTag status={status} statusColors={OrderStatusColors} text={text} showFullText />;
};

const defaultOrder = { status: '', externalType: -1 };

const StatusBar = (props: Props) => {
  const { t, order, isOverdue, loadingOrder } = props;
  const { externalType, status } = order || defaultOrder;
  const priority = order?.priority || DEFAULT_PRIORITY;

  const overdueTag = isOverdue ? getStatusTag(OrderStatus.OVERDUE, t(OrderStatus.OVERDUE)) : <></>;
  const statusTag = getStatusTag(status, t(status));
  const source = (optionLabelOverrides.source as SourceMap)[externalType] || externalType;
  const priorityText = translatePriority(priority, t);
  if (loadingOrder) {
    return <SkeletonText />;
  }

  return (
    <Styled.StatusBar>
      <StatusField title={t('Status')} value={statusTag} />
      <StatusField title={t('Source')} value={source} />
      <StatusField title={t('Priority')} value={priorityText} />
      {overdueTag}
    </Styled.StatusBar>
  );
};

export default StatusBar;
